/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/semantic-ui@2.4.2/dist/components/accordion.min.js
 * - /npm/semantic-ui@2.4.2/dist/components/dimmer.min.js
 * - /npm/semantic-ui@2.4.2/dist/components/api.min.js
 * - /npm/semantic-ui@2.4.2/dist/components/checkbox.min.js
 * - /npm/semantic-ui@2.4.2/dist/components/dropdown.min.js
 * - /npm/semantic-ui@2.4.2/dist/components/form.min.js
 * - /npm/semantic-ui@2.4.2/dist/components/modal.min.js
 * - /npm/semantic-ui@2.4.2/dist/components/progress.min.js
 * - /npm/semantic-ui@2.4.2/dist/components/rating.min.js
 * - /npm/semantic-ui@2.4.2/dist/components/search.min.js
 * - /npm/semantic-ui@2.4.2/dist/components/sidebar.min.js
 * - /npm/semantic-ui@2.4.2/dist/components/site.min.js
 * - /npm/semantic-ui@2.4.2/dist/components/tab.min.js
 * - /npm/semantic-ui@2.4.2/dist/components/visibility.min.js
 * - /npm/semantic-ui@2.4.2/dist/components/transition.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
